.header-background {
  background: white;
}
#header {
  position: fixed;
  z-index: 9;
  width: 100%;

  .header-logo {
    //width: 80%;
    max-width: 330px;
  }

  .custom-container {
    width: 90vw;
  }

  .mobile-header {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-btn-primary {
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }

  .header-list {
    list-style: none;
    padding: 0;
    justify-content: space-evenly;
    .header-list-item {
      a {
        font-size: 17px;
        line-height: 1.44;
        color: #ffffff;

        .colorful-link {
          color: #000000 !important;
        }
      }

      .active {
        font-size: 1px;
        font-weight: bold;
        font-weight: 900;
      }

      .ant-dropdown-trigger {
        cursor: pointer;
        font-size: 17px;
        color: white;
        .colorful-link {
          color: #000000;
        }
      }

      .colorful-link {
        color: #000000 !important;
        &:hover {
          text-decoration: underline;
        }
      }

      .ant-dropdown-open {
        font-weight: 900;
      }

      .active {
        font-size: 17px;
        font-weight: bold;
        font-weight: 900;
      }

      &:hover {
        a {
          font-weight: 900;
          text-decoration: underline;
        }
      }
    }
  }
}
.ant-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;

    .menu-header {
      position: absolute;
      top: 2%;
      width: 93%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-btn {
        border: none;
        box-shadow: none;
      }
    }

    .header-list {
      list-style: none;
      padding: 0;
      margin-top: 70px;

      .header-list-item {
        margin-bottom: 45px;
        a {
          font-size: 28px;
          line-height: 1.44;
          font-weight: 500;
          color: #000000;

          .colorful-link {
            color: #000000;
          }
        }

        .active {
          font-size: 28px;
          line-height: 1.44;
          font-weight: 500;
          color: #000000;
        }
      }
    }
  }
}
.ant-dropdown-menu {
  opacity: 0.8;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  background-color: #ededed !important;
  padding: 16px !important;

  .ant-dropdown-menu-item {
    a {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
    }
    .active {
      font-size: 18px;
      font-weight: bold;
      font-weight: 900;
    }

    &:hover {
      background: #e4dfdf;
    }
  }
}

.ant-menu-submenu-title {
  font-size: 28px;
  line-height: 1.44;
  font-weight: 500;
  color: #000000 !important;
  padding-left: 0 !important;
}

.ant-menu-item {
  padding-left: 0px !important;

  .mobile-menu-item {
    font-size: 24px !important;
    line-height: 1.42 !important;
    color: #000000 !important;
    padding-left: 20px !important;
    font-weight: normal !important;
    text-decoration: none !important;
  }
}

.ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-inline {
  border-right: none !important;
}

.language-container {
  .activeLang {
    font-weight: 900;
  }

  .language-line {
    border-left: solid 0.8px #ffffff !important;
    height: 26px !important;
  }

  .colorful-link-line {
    border-left: solid 0.8px #000000;
    height: 26px;
  }

  .colorful-link-lang {
    color: #000000 !important;

    &:hover {
      text-decoration: underline;
    }
  }
  .colorful-link {
    color: #000000 !important;
  }

  .language-button {
    border: none;
    font-size: 18px;
    background: transparent;
    &:active,
    &:focus {
      outline: none;
    }
  }

  .colorful-link-lang {
    color: #ffffff !important;
  }

  .not-colorful-link {
    color: #000000 !important;
  }
}
@media (min-width: 768px) {
  #header {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  #header {
    .header-logo {
      width: 90%;
    }
  }
}
