#technology {
  .technology-container {
    padding-top: 120px;

    .margins {
      margin-bottom: 40px;
    }

    .title {
      font-size: 18px;
      font-weight: 900;
      color: #000000;
      line-height: 1.45;
      margin-top: 40px;
    }

    .first-area {
      flex-direction: column-reverse;
    }

    .third-area {
      flex-direction: column-reverse;
    }

    .subtitle {
      font-size: 14px;
      color: #000000;
      font-weight: 500;
      line-height: 1.45;
    }

    .description {
      font-size: 14px;
      line-height: 1.45;
      color: #000000;
    }
  }

  .documents-link {
    font-size: 18px;
    font-weight: 500;
    line-height: 2.36;
    color: #3c3c3b;
  }
  .arrow-black {
    width: 22px;
  }
}

@media (min-width: 768px) {
  #technology {
    .documents-link {
      font-size: 22px;
    }

    .arrow-black {
      width: 29px;
    }

    .technology-container {
      padding-top: 240px;

      .margins {
        margin-bottom: 120px;
      }

      .first-area {
        flex-direction: row;
      }

      .third-area {
        flex-direction: row;
      }

      .title {
        font-size: 30px;
        line-height: 1.38;
        margin-top: 0;
      }

      .subtitle {
        font-size: 16px;

        line-height: 1.38;
      }

      .description {
        font-size: 16px;

        line-height: 1.38;
      }
    }
  }
}
