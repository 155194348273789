#contact {
  .banner-container {
    height: 728px;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  .info-box-top {
    background-color: #3c3c3b;
    height: 312px;
    font-size: 12px;
    line-height: 1.38;
    color: #ffffff;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 312px;
    position: absolute;
    top: 8rem;
    right: 0;

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.44;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  .info-box-bottom {
    background-color: #3c3c3b;
    height: 312px;
    font-size: 12px;
    line-height: 1.38;
    color: #ffffff;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 312px;
    position: absolute;
    top: 31rem;
    left: 0;

    img {
      width: 24px;
      height: 24px;
    }

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.44;
    }

    .inspect {
      font-size: 13px;
      font-weight: 500;
      line-height: 2.46;
      color: #ffffff;
    }
  }

    .mail {
      font-size: 16px;
      line-height: 1.38;
      color: #ffffff;
    }
  
  .gray-area {
    min-height: 586px;
    padding-top: 126px;
    background-color: #f7f7f7;

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.4;
      color: #000000;
      margin-bottom: 23px;
    }

    .job-form {
      input {
        background: transparent;
        border-bottom: 1px solid #000000;
        border-top: none;
        border-left: none;
        border-right: none;

        &:focus {
          box-shadow: none;
        }
      }

      textarea {
        border: 1px solid #000000;
        background-color: transparent;
      }

      .ant-input::placeholder {
        opacity: 0.3;
        font-size: 12px;
        line-height: 1.38;
        color: #000000;
        padding-top: 8px;
      }

      .ant-input {
        margin-bottom: 10px;
        &:focus {
          box-shadow: none;
        }
      }

      .submit-button {
        display: flex;
        align-items: center;
        margin-left: auto;
        background: transparent;
        border: none;
        box-shadow: none;
        margin-bottom: 48px;

        span {
          font-size: 13px;
          font-weight: 500;
          line-height: 2.36;
          color: #3c3c3b;
        }
        .arrow-black {
          width: 22px;
        }
      }
    }
  }

  .description-area {
    margin-top: 40px;

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.4;
      color: #000000;
    }

    .description {
      -webkit-text-stroke: 0.3px #707070;
      font-size: 14px;
      line-height: 1.38;
      color: #000000;
    }

    img {
      width: 100%;
    }

    .area-margin {
      margin-bottom: 85px;
      margin-top: 50px;
    }
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      span:not(.ant-radio) {
        font-size: 12px;
        font-weight: 500;
        line-height: 1.38;
        color: #000000;
      }

      .ant-radio-checked {
        .ant-radio-input {
          width: 11px;
          height: 16px;
          border: solid 1px #3c3c3b;
          background: transparent;

          .ant-radio-inner {
            border-color: #3c3c3b;
            background: transparent;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  #contact {
    .banner-container {
      height: 550px;
    }

    .info-box-bottom {
      top: 21rem;
    }

    .info-boxes {
      position: absolute;
      top: 29rem;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;

      .info-box {
        background-color: #3c3c3b;
        height: 475px;
        font-size: 16px;
        line-height: 1.38;
        color: #ffffff;
        padding: 5rem 3.75rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 475px;
      }

      .title {
        font-size: 30px;
        font-weight: 900;
        line-height: 1.4;
      }

      .inspect {
        font-size: 22px;
        font-weight: 500;
        line-height: 2.36;
        color: #ffffff;
      }
    }

    .gray-area {
      font-size: 30px;

      .job-form {
        .submit-button {
          span {
            font-size: 22px;
            margin-bottom: 0;
          }

          .aroow-black {
            width: 29px;
          }
        }

        .ant-input::placeholder {
          font-size: 16px;
        }
      }
    }

    .ant-radio-group {
      .ant-radio-wrapper {
        span:not(.ant-radio) {
          font-size: 16px;
        }

        .ant-radio-checked {
          .ant-radio-input {
            width: 16px;
          }
        }
      }
    }

    .description-area {
      .area-margin {
        margin-bottom: 100px;
        margin-top: 80px;
      }

      .title {
        font-size: 30px;
      }

      .description {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 992px) {
  #contact {
      .banner-container {
      height: 752px;
    }
    .info-box-top {
      display: none;
    }
    .info-box-bottom {
      display: none;
    }
  }
}
