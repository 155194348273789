#home-page {
  .banner-container {
    height: 811px;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  .react-parallax-one {
    .react-parallax-bgimage {
      left: 50%;
      width: 101% !important;
      top: 30%;
      height: 226px !important;
    }

    .react-parallax-content {
      height: 560px;
      margin-top: 250px;
    }
  }

  .info-area {
    height: 560px;
    width: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;

    .custom-container-info {
      width: 68vw;
      margin: auto;
    }

    .title {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.05;
      color: #3c3c3b;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 900;
      color: #000000;
      line-height: 1.05;
      margin-bottom: 30px;
    }

    .description {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.5;
      color: #000000;
      margin: 0;
    }

    .description-bold {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.5;
      color: #000000;
    }

    .bold-text {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.4;
      color: #000000;
      margin-bottom: 0;
    }

    .line {
      width: 16.2px;
      border: solid 1px #3c3c3b;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 14px;
    }

    .light-text {
      font-size: 14px;
      line-height: 1.22;
      color: #9d9d9c;
    }

    .margin-area {
      margin-bottom: 41px;
    }

    .right-side {
      text-align: center;
    }
  }

  .banner-container-two {
    height: 500px;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;

    .custom-container-mobile {
      .products-card {
        height: 272px;
        width: 100%;
        border: solid 1px #5fa1be;
        background-color: #ffffff;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: 18px;
          font-weight: 900;
          line-height: 1.44;
          color: #000000;
        }

        .subtitle {
          font-size: 13px;
          font-weight: 500;
          line-height: 1.46;
          color: #000000;
        }

        .arrow-black {
          width: 24px;
        }
      }
    }
  }
  .react-parallax-two {
    height: 100%;

    .react-parallax-bgimage {
      left: 50%;
      top: 20%;
      width: 101% !important;
      height: 400px !important;
    }

    .react-parallax-content {
      height: auto;
      margin-top: 160px;
    }
  }
  .mobile-info {
    position: relative;
    z-index: 8;
    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.44;
      color: #000000;
    }

    .subtitle {
      font-size: 13px;
      font-weight: 500;
      line-height: 1.46;
      color: #000000;
    }
    .more-info-link {
      font-size: 13px;
      line-height: 2.38;
      color: #9d9d9c;
      text-decoration: underline;
    }

    .mobile-technology {
      .image-technology {
        width: 173px;
      }

      .go-to-page-link {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.46;
        color: #3c3c3b;
      }

      .arrow-black {
        width: 24px;
      }
    }

    .text-news {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.45;
      color: #3c3c3b;
      text-decoration: underline;
    }
  }

  .custom-container {
    width: 87vw;
    margin: auto;
    height: 100%;
  }

  .info-area-two {
    background: #ffffff;
    height: 100%;
    border: solid 1px #5fa1be;
    height: 100%;

    .title {
      font-size: 30px;
      font-weight: 900;
      line-height: 1.4;
      color: #000000;
    }

    .title-products {
      margin-top: 70px;
      margin-bottom: 76px;
    }

    .title-services {
      margin-bottom: 40px;
    }

    .title-news {
      margin-bottom: 31px;
      margin-top: 112px;
    }

    .title-technology {
      margin-bottom: 46px;
      margin-top: 112px;
    }

    .text-news {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #3c3c3b;
      text-decoration: underline;
    }

    .image-technology {
      width: 140px;
      margin-bottom: 37px;
    }

    .more-info-link {
      font-size: 16px;
      line-height: 2.81;
      color: #9d9d9c;
    }

    .margin {
      margin-bottom: 122px;
    }

    .slider-card {
      width: 21vw;
    }

    .documents-link-container {
      .go-to-page-link {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.44;
        color: #3c3c3b;
      }
    }

    .arrow-black {
      width: 22px;
      margin-right: 11px;
    }
  }
}

@media (min-width: 768px) {
  #home-page {
    .banner-container {
      height: 1202px;
      position: relative;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: cover !important;
    }

    .banner-container-two {
      height: 1800px;
      position: relative;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: cover !important;
    }

    .react-parallax-one {
      height: 1202px;

      .react-parallax-bgimage {
        width: 101% !important;
        height: 573px !important;
        top: 18%;
      }

      .react-parallax-content {
        height: 629px;
        margin-top: 563px;
      }
    }

    .react-parallax-two {
      .react-parallax-bgimage {
        left: 50%;
        top: 20%;
        width: 100% !important;
        height: 534px !important;
      }

      .react-parallax-content {
        height: 100%;
        margin-top: 260px;
      }
    }
  }
  .info-area {
    height: 629px !important;

    .title {
      font-size: 40px !important;
      margin-bottom: 10px !important;
    }

    .subtitle {
      font-size: 30px !important;
      margin-bottom: 30px !important;
    }

    .description {
      font-size: 20px !important;
    }

    .description-bold {
      font-size: 20px !important;
    }

    .bold-text {
      font-size: 30px !important;
    }

    .line {
      width: 22.5px !important;
      margin-left: auto !important;
      margin-right: inherit !important;
      margin-bottom: 27.5px;
    }

    .light-text {
      font-size: 18px !important;
    }

    .margin-area {
      margin-bottom: 80px !important;
    }

    .right-side {
      text-align: right !important;
    }
  }

  .info-area-two {
    background: #ffffff;
    border: solid 1px #5fa1be;

    .title {
      font-size: 30px;
      font-weight: 900;
      line-height: 1.4;
      color: #000000;
    }

    .technology-links {
      margin-right: 10px;
    }

    .title-products {
      margin-top: 70px;
      margin-bottom: 76px;
    }

    .title-services {
      margin-bottom: 40px;
    }

    .title-news {
      margin-bottom: 31px;
      margin-top: 112px;
    }

    .title-technology {
      margin-bottom: 46px;
      margin-top: 112px;
    }

    .text-news {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: #3c3c3b;
      text-decoration: underline;
    }

    .image-technology {
      width: 140px;
      margin-bottom: 37px;
    }

    .more-info-link {
      font-size: 16px;
      line-height: 2.81;
      color: #9d9d9c;
    }

    .slick-next {
      background: url("/assets/images/home/sider-next-arrow.svg") center center no-repeat !important;
      width: 30px;
      height: 30px;
      right: -30px;

      &:before {
        display: none;
      }
    }

    .slick-prev {
      background: url("/assets/images/home/sider-previous-arrow.svg") center center no-repeat !important;
      width: 30px;
      height: 30px;
      left: -30px;

      &:before {
        display: none;
      }
    }

    .margin {
      margin-bottom: 122px;
    }

    .slider-card {
      width: 20vw;
      height: 381.3px;
      background-color: #5fa1be;
    }

    .documents-link-container {
      .go-to-page-link {
        font-size: 16px !important;
      }
    }

    .arrow-black {
      width: 22px;
      margin-right: 11px;
    }
  }
}

@media (min-width: 992px) {
  #home-page {
    .info-area-two {
      background: #ffffff;

      .arrow-black {
        width: 29px;
      }

      .documents-link-container {
        .go-to-page-link {
          font-size: 16px !important;
        }
      }

    }
  }
}

@media (min-width: 1200px) {
  #home-page {

    .info-area-two {

      .technology-links {
        margin-right: 60px;
      }
    }
  }
}

@media (min-width: 1440px) {
  #home-page {
    .info-area-two {
      background: #ffffff;

      .arrow-black {
        width: 29px;
      }

        .documents-link-container {
        .go-to-page-link {
          font-size: 18px !important;
        }
      }
      
    }
  }
}

@media (min-width: 2000px) {
  #home-page {
    .banner-container-two {
      height: 1900px;
    }

    .info-area-two {
      .products-type {
        width: 65%;
      }

      .technology-links {
        margin-right: 150px;
      }
    }
  }
}
