#services {
  .services-container {
    padding-top: 120px;
    margin-bottom: 42px;

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.4;
      text-align: left;
      color: #000000;
      margin-bottom: 14px;
    }

    .description {
      color: #000000;
      font-size: 14px;
    }
  }

  .image-desktop {
    max-width: 344px;
  }

}

@media (min-width: 768px) {
  #services {

    .services-container {
      padding-top: 230px;

      .title {
        font-size: 30px;
        line-height: 1.38;
      }

    .description {
      font-size: 16px;
    }
    }
  }

  .margin {
    margin-bottom: 40px;
  }

  .last-margin {
    margin-bottom: 160px;
  }
}

@media (min-width: 992px) {
  #services {
   .services-container {
      padding-top: 200px;
      
      .title {
        font-size: 30px;
        line-height: 1.38;
      }
    }
  }
}

@media (min-width: 1200px) {
  #products-steel {
  
    .services-container {
      margin-bottom: 154px;

      .download-products {
        flex-direction: row;

        .product-desktop {
          width: 900px;
        }
      }
    }
  }
}
