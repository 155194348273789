#products-packaging {
  .products-container {
    padding-top: 120px;
    margin-bottom: 42px;

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.4;
      text-align: left;
      color: #000000;
    }

    .download-products {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .product-desktop {
      width: 100%;
    }
  }
  .documents-link-container {
    margin-top: 40px;
    cursor: pointer;

    .documents-link {
      font-size: 18px;
      font-weight: 500;
      line-height: 2.36;
      color: #3c3c3b;
    }
  }

  .arrow-black {
    width: 22px;
    margin-left: 0px;
  }
}

@media (min-width: 768px) {
  #products-packaging {
    .documents-link-container {
      .documents-link {
        font-size: 22px;
      }
    }

    .arrow-black {
      width: 29px;
      margin-left: 10;
    }

    .products-container {
      padding-top: 170px;

      .title {
        font-size: 30px;
        line-height: 1.38;
      }
    }
  }
}

@media (min-width: 992px) {
  #products-packaging {
    .arrow-black {
      width: 29px;
    }

    .products-container {
       padding-top: 200px;
      .title {
        font-size: 30px;
        line-height: 1.38;
      }
    }
  }
}

@media (min-width: 1200px) {
  #products-packaging {
    .products-container {
      margin-bottom: 154px;
    }

    .documents-link-container {
      margin-top: 0;
      .documents-link {
        font-size: 22px;
      }
    }
    .products-container {
      .download-products {
        flex-direction: row;
        .product-desktop {
          width: 880px;
        }
      }
    }
    .arrow-black {
      margin-left: 35px;
    }
  }
}
