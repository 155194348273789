#documents {
  position: relative;

  .banner-container {
    height: 220px;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  .custom-height-container {
    min-height: 850px;
    margin-bottom: 108px;
  }

  .documents-area {
    position: relative;
    background-color: #ffffff;
    min-height: 800px;

    .title {
      font-size: 30px;
      font-weight: 900;
      line-height: 1.4;
      font-size: 18px;
      line-height: 0.89;
      color: #ffffff;
      position: absolute;
      top: -3rem;
    }

    .subtitle {
      font-size: 13px;
      font-weight: 500;
      line-height: 2.85;
      color: #000000;
    }

    .download-link {
      display: flex;
      flex-direction: row;
    }

    .documents-link-container {
      margin-left: 20px;
      cursor: pointer;
      .documents-link {
        font-size: 13px;
        font-weight: 500;
        line-height: 2.36;
        color: #3c3c3b;
      }

      .arrow-black {
        width: 22px;
      }
    }
    .certificate {
      width: 152px;
      height: 215px;
    }

    .item {
      margin-top: 55px;
    }
  }
}

@media (min-width: 768px) {
  #documents {
    .banner-container {
      height: 586px;
    }

    .custom-height-container {
      margin-bottom: 91px;
    }

    .documents-area {
      position: absolute;
      top: -7rem;
      background-color: #f7f7f7;
      min-height: 800px;
      padding: 50px 0;

      .title {
        font-size: 30px;
        font-weight: 900;
        line-height: 1.4;
        color: #ffffff;
        position: absolute;
        top: -8rem;
      }

      .margin {
        margin-top: 108px;
        margin-bottom: 55px;
      }

      .item {
        margin-top: 0;
      }

      .subtitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.42;
        color: #000000;
        height: 68px;
      }

      .download-link {
        display: flex;
        flex-direction: column;
      }

      .documents-link-container {
        margin-left: 0;
        margin-top: 35px;

        .documents-link {
          font-size: 18px;
          font-weight: 500;
          line-height: 2.36;
          color: #3c3c3b;
        }

        .arrow-black {
          width: auto;
        }
      }

      .certificate {
        width: 100%;
        height: auto;
      }
    }

    .description-area {
      .description {
        padding-right: 4rem !important;
        font-size: 14px;
      }

      .title {
        font-size: 30px;
      }
    }

    .sub-description-container {
      margin-top: 130px;
      margin-bottom: 65px;

      .sub-description {
        font-size: 25px;
        text-align: center;
      }
    }

    .info-box {
      background-color: #3c3c3b;
      width: 560px;
      height: 296px;
      padding: 38px;
      position: absolute;
      top: 37rem;
      right: 13rem;
      z-index: 2;

      .title {
        font-size: 30px;
        font-weight: 900;
        color: #ffffff;
      }

      .description {
        font-size: 16px;
        line-height: 1.38;
        color: #ffffff;
      }
    }

    .gray-area {
      .slider-card {
        background-color: #ededed;
        height: 150px;
        margin-left: auto;

        p {
          font-size: 14px;
        }
      }

      .slider-container {
        width: 85%;
      }

      .slick-list {
        padding: 0px 10px !important;
      }
    }
  }
}

@media (min-width: 992px) {
  #documents {
    .custom-height-container {
      min-height: 1100px;
      margin-bottom: 91px;
    }

    .description-area {
      .description {
        padding-right: 4rem !important;
        font-size: 14px;
      }

      .title {
        font-size: 30px;
      }

      .area-margin {
        margin-top: 188px;
        margin-bottom: 127px;
      }
    }
    .area-one {
      margin-bottom: 115px;
    }

    .gray-area {
      height: 555px;
      background-color: #d2d1d1;
      padding: 0;
      margin-bottom: 165px;

      .slider-area {
        height: 100%;
      }

      .title {
        font-size: 30px;
      }

      .description {
        font-size: 16px;
      }

      .slider-card {
        background-color: #ededed;
        width: 85%;
        height: 300px;
        margin-left: auto;
        p {
          font-size: 20px;
        }
      }

      .slider-container {
        width: 60%;
      }

      .slick-list {
        padding: 0px 0 !important;
      }

      .slick-next {
        &:before {
          display: none;
        }
      }

      .slick-prev {
        &:before {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  #documents {
    .gray-area {
      height: 555px;
      background-color: #d2d1d1;
      .slider-card {
        background-color: #ededed;
        width: 90%;
        height: 300px;
        margin-left: auto;
        p {
          font-size: 25px;
        }
      }

      .slider-container {
        width: 60%;
      }

      .slick-list {
        padding: 0px 180px !important;
      }
    }

  }
}
