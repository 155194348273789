#footer {
  bottom: 0;
  width: 100%;
  position: relative;
  z-index: 999;

  .question-area {
    background: #f7f7f7;
    height: 170px;

    .first {
      justify-content: space-evenly;
    }

    .text-bold {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
    }

    .arrow-black {
      width: 24px;
    }

    .text-light {
      font-size: 12px;
      color: #9d9d9c !important;
    }

    .contact {
      font-size: 11x;
      font-weight: 500;
      color: #000000;
    }

    .contact-form-link {
      font-size: 14px;
      font-weight: 500;
      line-height: 3.25;
      color: #9d9d9c;
    }
  }

  .logo-container {
    margin-right: 160px;
  }

  .first-list {
    margin-right: 100px;
  }

  .footer-list {
    list-style: none;

    .footer-list-item {
      margin-bottom: 30px;
      .links {
        cursor: pointer;
        font-size: 14px;
        line-height: 1.43;
        text-align: left;
        color: #000000;
        &:hover {
          text-decoration: underline;
          font-weight: 500;
        }
      }

      a {
        font-size: 14px;
        line-height: 1.43;
        text-align: left;
        color: #000000;

        &:hover {
          text-decoration: underline;
          font-weight: 500;
        }
      }

      .link {
        font-size: 14px;
        line-height: 1.43;
        text-align: left;
        color: #000000;

        &:hover {
          text-decoration: underline;
          font-weight: 500;
        }
      }
    }

    .footer-list-item-mobile {
      margin-bottom: 28px;
      a {
        font-size: 12px;
        line-height: 1.43;
        text-align: left;
        color: #000000;
        text-decoration: underline;
      }
      .footer-link {
        font-size: 12px;
        line-height: 1.43;
        text-align: left;
        color: #000000;
        text-decoration: underline;
      }
    }
  }
  .social-media-links-mobile {
    display: flex;
    align-items: center;
    img {
      margin-top: 20px;
      margin-bottom: 68px;
    }
  }
}

@media (min-width: 768px) {
  #footer {
    .question-area {
      height: 224px;

      .arrow-black {
        width: auto;
      }

      .text-bold {
        font-size: 18px;
      }

      .text-light {
        font-size: 16px;
      }

      .contact {
        font-size: 14px;
      }

      .contact-form-link {
        font-size: 14px;
      }
    }
  }
}

@media (min-width: 992px) {
  #footer {
    .question-area {
      height: 224px;

      .text-bold {
        font-size: 22px;
      }

      .text-light {
        font-size: 18px;
      }

      .contact {
        font-size: 16px;
      }

      .contact-form-link {
        font-size: 16px;
      }
    }
  }
}
