#about-us {
  .banner-container {
    height: 188px;
    position: relative;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  .sub-description-container {
    margin-top: 352px;
    margin-bottom: 42px;

    .sub-description {
      font-size: 14px;
      line-height: 1.4;
      text-align: left;
      color: #3c3c3b;
      margin: 30px 0;
    }
  }

  .info-box {
    background-color: #3c3c3b;
    padding: 28px;
    position: absolute;
    z-index: 2;
    width: auto;
    height: 310px;
    top: auto;
    right: 0;

    .title {
      font-size: 18px;
      font-weight: 900;
      color: #ffffff;
    }

    .description {
      font-size: 14px;
      line-height: 1.38;
      color: #ffffff;
    }
  }

  .description-area {
    .description {
      padding-right: 1rem !important;
      font-size: 14px;
      line-height: 1.43;
      color: #000000;
    }

    .title {
      font-size: 18px;
      font-weight: 900;
      color: #000000;
    }

    .area-margin {
      margin-top: 0;
      margin-bottom: 70px
    }
  }

  .area-one {
    margin-bottom: 0;
  }

  .gray-area {
    height: 390px;
    padding: 30px 0;
    background-color: #d2d1d1;
    position: relative;
    margin-bottom: 7px;

    .slider-area {
      height: auto;

      .deneme {
        width: 90%;
        margin: auto;
      }
    }

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.38;
      color: #000000;
    }

    .description {
      font-size: 14px;
      line-height: 1.38;
      color: #000000;
    }

    .slider-card {
      background-color: #ededed;
      width: 85%;
      height: 160px;
      margin-left: inherit;

      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        padding: 20px;
      }
    }

    .slider-container {
      width: 90%;
    }

    .slick-dots {
      bottom: -55px;
      text-align: right;
      right: 3rem;
      li {
        margin: 0 19px;
        button {
          &::before {
            font-size: 16px;
            color: #ededed;
            opacity: 1;
          }
        }
      }

      .slick-active {
        button {
          &::before {
            color: #ffffff;
            opacity: 1;
          }
        }
      }
    }

    .slick-next {
      display: none !important;
      &:before {
        display: none;
      }
    }
    .custom-arrows {
      z-index: 999;
      width: 33rem;
      position: absolute;
      right: 2%;
      bottom: -32px;

      .next-arrow {
        position: absolute;
        right: 0;
        cursor: pointer;
      }
      .previous-arrow {
        position: absolute;
        cursor: pointer;
      }
    }

    .slick-prev {
      display: none !important;
      &:before {
        display: none;
      }
    }

    .slick-list {
      padding: 0px 115px !important;
    }
  }
}

@media (min-width: 768px) {
  #about-us {
    .banner-container {
      height: 800px;
    }

    .description-area {
      .description {
        padding-right: 4rem !important;
        font-size: 16px;
      }

      .title {
        font-size: 30px;
      }
    }

    .sub-description-container {
      margin-top: 130px;
      margin-bottom: 65px;

      .sub-description {
        font-size: 25px;
        text-align: center;
      }
    }

    .info-box {
      background-color: #3c3c3b;
      width: 560px;
      height: 296px;
      padding: 38px;
      position: absolute;
      top: 14rem;
      right: 13rem;
      z-index: 2;

      .title {
        font-size: 30px;
        font-weight: 900;
        color: #ffffff;
      }

      .description {
        font-size: 16px;
        line-height: 1.38;
        color: #ffffff;
      }
    }

    .gray-area {
      .slider-card {
        background-color: #ededed;
        height: 150px;
        margin-left: auto;

        p {
          font-size: 14px;
        }
      }

      .slider-container {
        width: 85%;
      }

      .slick-list {
        padding: 0px 10px !important;
      }
    }
  }
}

@media (min-width: 992px) {
  #about-us {
    .description-area {
      .description {
        padding-right: 4rem !important;
      
      }

      .title {
        font-size: 30px;
      }

      .area-margin {
        margin-top: 188px;
        margin-bottom: 127px;
      }
    }
    .area-one {
      margin-bottom: 115px;
    }

    .gray-area {
      height: 555px;
      background-color: #d2d1d1;
      padding: 0;
      margin-bottom: 165px;

      .slider-area {
        height: 100%;
      }

      .title {
        font-size: 30px;
      }

      .description {
        font-size: 16px;
      }

      .slider-card {
        background-color: #ededed;
        width: 85%;
        height: 300px;
        margin-left: auto;
        p {
          font-size: 19px;
        }
      }

      .slider-container {
        width: 60%;
      }

      .slick-list {
        padding: 0px 0 !important;
      }

      .slick-next {
        &:before {
          display: none;
        }
      }

      .slick-prev {
        &:before {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  #about-us {
    .gray-area {
      height: 555px;
      background-color: #d2d1d1;
      .slider-card {
        background-color: #ededed;
        width: 90%;
        height: 300px;
        margin-left: auto;
        p {
          font-size: 25px;
        }
      }

      .slider-container {
        width: 60%;
      }

      .slick-list {
        padding: 0px 180px !important;
      }
    }
  }
}


  
@media (min-width: 1380px)  {
  #about-us {
    .gray-area {
      .slider-card {
        width: 85%;
      }
    }
  }
}

@media (min-width: 1800px)  {
  #about-us {
    .gray-area {
      .slider-card {
        width: 90%;
      }
    }
  }
}