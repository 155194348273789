

.activeLang {
    font-weight: 900;
}

.language-line {
    border-left: solid 0.8px #000000;
    height: 26px;
}

.colorful-link-line {
    border-left: solid 0.8px #000000;
    height: 26px;
}

.language-button {
    border: none;
    font-size: 22px;
    background: transparent;
}

.colorful-linkk {
  color: #ffffff !important;
}

.not-colorful-link {
  color: #000000 !important;
}