#products-stainless {
  .products-container {
    padding-top: 120px;
    margin-bottom: 42px;

    .title {
      font-size: 18px;
      font-weight: 900;
      line-height: 1.4;
      text-align: left;
      color: #000000;
    }

    .download-products {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .documents-link-container {
        margin-top: 40px;
        cursor: pointer;
        
        .documents-link {
          font-size: 18px;
          font-weight: 500;
          line-height: 2.36;
          color: #3c3c3b;
        }
      }

      .product-desktop {
        width: 100%;
      }

      .arrow-black {
        width: 22px;
        margin-left: 0px;
      }
    }
  }
}

@media (min-width: 768px) {
  #products-stainless {
    .products-container {
      padding-top: 170px;

      .title {
        font-size: 30px;
        line-height: 1.38;
      }
      .documents-link-container {
        .documents-link {
          font-size: 22px;
        }
      }

      .arrow-black {
        width: 29px;
        margin-left: 10;
      }
    }
  }
}

@media (min-width: 992px) {
  #products-stainless {
    .products-container {
      padding-top: 200px;
      .title {
        font-size: 30px;
        line-height: 1.38;
      }

      .arrow-black {
        width: 29px;
      }
    }
  }
}

@media (min-width: 1200px) {
  #products-stainless {
    .products-container {
      margin-bottom: 154px;
      .download-products {
        flex-direction: row;
        .product-desktop {
          width: 880px;
        }
        .documents-link-container {
          margin-top: 0;
          .documents-link {
            font-size: 22px;
          }

          .arrow-black {
            margin-left: 35px;
          }
        }
      }
    }
  }
}
